@import "./variables";
.active-btn,
.active-btn2 {
    background: $green-color !important;
    border-color: $green-color !important;
    &:focus {
        background: $green-color;
        border-color: $green-color;
    }
}
.error-btn {
    background: $error-color !important;
    border-color: $error-color !important;
    &:focus {
        background: $error-color;
        border-color: $error-color;
    }
}

.afterFormButtons {
    margin-top: 10px;
    .btn {
        &:not(:first-child) {
            margin-left: 10px;
        }
    }
}

button:focus {
    outline: none !important;
}

.btn {
    min-height: 48px;
    border-radius: 8px;
    color: $white;
    transition: $transition-config;
    cursor: pointer;
    line-height: 1rem;
    margin: 0;
    vertical-align: middle;
    padding: 0.5rem 1.5rem;
    &:focus,
    &:active {
        outline: 0;
        box-shadow: none;
    }
    &.valider {
        min-width: 250px;
    }
    &.btn-blue {
        background: $blue-btn-color;
        border-color: $blue-btn-color;
        &:hover {
            color: $white;
            background: $blue-btn-hover-color;
        }
        &:disabled {
            cursor: default;
            background: $disabled-background;
            color: $disabled-text-color;
            border: none;
            .ng-fa-icon {
                color: white;
            }
        }
    }
    &.btn-red {
        background: $red-btn-color;
        border-color: $red-btn-color;
        &:hover {
            color: $white;
            background: $red-btn-hover-color;
        }
        &:disabled {
            cursor: default;
            background: $disabled-background;
            color: $disabled-text-color;
            border: none;
            .ng-fa-icon {
                color: white;
            }
        }
    }
    &.get-sms {
        margin-top: 50px;
    }
    .ng-fa-icon {
        margin-right: 10px;
    }
}
button.btn.btn-primary[disabled] {
    background-color: #e5e5e5;
    color: $middle-grey;
    border-color: $disabled-background;
    cursor: default;
}

@media (max-width: 767px) {
    .btn.valider {
        min-width: 200px;
    }
}
