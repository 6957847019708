@import "./variables";
@import "./breakpoints";

.table-like {
    float: none;
    position: absolute;
    top: 50%;
    left: 0;
    transform: translate(0%, -50%);
    margin-top: 0;
    margin-bottom: 0;
}

ul.list-arrows {
    margin-bottom: 20px;
    list-style: none;
    li {
        padding-bottom: 8px;
        font-size: 14px;
        color: $grey-text;
        line-height: 1.5;
    }
}

.col-speech {
    margin: 1rem 0;
    padding: 1rem;
    border-radius: 5px;
    text-align: left;
    background-color: $background-color;
}

.row {
    &.with-speech {
        background-color: $background-color;
        margin: 1rem 0;
        padding: 1rem 0;
        .speech {
            .files {
                .label {
                    color: $text-default;
                    font-size: 14px;
                }
                ul {
                    list-style-type: none;
                    display: inline-block;
                    li {
                        color: $text-default;
                        cursor: pointer;
                        font-weight: 600;
                        text-decoration: underline;
                        display: inline-block;
                        margin-left: 15px;
                        font-size: 14px;
                        line-height: 1.5;
                        &:hover {
                            text-decoration: none;
                            cursor: pointer;
                        }
                    }
                }
            }
        }
    }
    &.with-radius {
        border-radius: 5px;
    }
    &.white {
        background-color: white;
    }
    &.with-col-speech {
        .speech {
            background-color: $background-color;
            margin: 0.5rem 1rem;
            padding-left: 1rem;
            padding-right: 1rem;
            .speech-title {
                color: $text-default;
            }
        }
    }
}

.form-panel {
    padding: 15px 5px;
    background-color: #f2f2f2;
    .form-group {
        margin: 0;
    }
}

.yes-invalid {
    .mat-combobox {
        &.ng-invalid {
            .mat-radio-button {
                &:not(.mat-radio-checked, .mat-radio-disabled) {
                    .mat-radio-label {
                        background: $blue-btn-color !important;
                    }
                }
            }
        }
    }
}

.small-item {
    font-size: 13px !important;
}

.auto-height {
    height: auto !important;
}

.no-title {
    .form-group {
        margin-top: -1rem !important;
        .mat-form-field {
            margin-top: 30px;
        }
    }
    &.in-table-cell {
        .mat-form-field {
            margin-top: 25px;
            margin-bottom: 7px;
        }
    }
}

.error-to-bottom {
    .mat-form-field {
        .mat-error {
            bottom: -20px;
            left: 0;
        }
    }
}

.question-error-wrapper {
    position: absolute;
    right: 0;
}

.question-error-block {
    margin-top: -36px;
}

.mb-6 {
    margin-bottom: 4rem !important;
}

.box-separator {
    position: absolute;
    left: 50%;
    top: 45%;
    transform: translate(-50%, 100%);
    text-transform: uppercase;
    font-size: 15px;
}

.form-euro {
    position: absolute;
    top: 21px;
    font-size: 22px;
    right: -10px;
    color: $text-default;
    font-weight: 300;
}

.alert {
    padding: 15px;
    margin-top: 20px;
    border: 1px solid transparent;
    border-radius: 4px;
    text-align: left;
    font-weight: 600;
    &.alert-danger {
        color: $alert-danger-color;
        background-color: $alert-danger-bg-color;
        border-color: $alert-danger-border-color;
    }
}

.recap p,
.recap li,
ul.list-arrows li {
    font-size: 14px;
    color: $grey-text;
    line-height: 1.5;
    .answer {
        color: $text-color;
    }
}

.recap-my-profil,
.recap-promo {
    &.compact {
        li {
            padding-bottom: 0;
        }
    }
    ol {
        counter-reset: item;
        list-style-type: none;
        li {
            display: block;
            margin-bottom: 20px;
            margin-left: 25px;
            &::before {
                content: counter(item) " - ";
                counter-increment: item;
                display: inline-block;
                width: auto;
                margin-left: -20px;
                margin-right: 5px;
                font-weight: 600;
            }
        }
    }
    ul {
        counter-reset: item;
        &.list-arrows {
            list-style: none;
            li {
                margin-bottom: 0px;
                &:before {
                    content: "";
                    background: url("../img/arrow-right.svg") no-repeat;
                    width: 13px;
                    height: 10px;
                    display: inline-block;
                    margin-right: 5px;
                }
            }
        }
    }
}

.hidden {
    display: none;
}

.dib {
    display: inline-block;
}

.vat {
    vertical-align: top;
}

.mt-no-title {
    margin-top: 1.389rem;
}

.underlined-subheader {
    font-size: 16px;
    padding-top: 20px;
    padding-bottom: 10px;
    text-decoration: underline;
    color: $text-default;
}

.oui-card,
.none-card {
    form {
        text-align: left;
    }
}

.border-dashed {
    height: 99%;
    width: 50%;
    border-right: 1px dashed $disabled-background;
}

.border-bottom-dashed {
    border-bottom: 1px dashed $disabled-background;
}

.dt {
    display: table;
    width: 100%;
}

.w100 {
    width: 100% !important;
}

.p-left {
    text-align: left;
}

.none-card .radio-label {
    width: 8rem;
}

.mt-0 {
    .form-group {
        margin-top: 0;
    }
}

.visible-sm,
.visible-xs {
    display: none;
}

.centered-by-margin {
    margin: 0 auto;
}

.filter-menu {
    &:hover {
        .filter-content {
            display: block;
            right: -50px;
            top: 29px;
        }
    }
    .filter-content {
        display: none;
        position: absolute;
        background-color: white;
        box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
        padding: 2px 5px;
        z-index: 1;
        margin-bottom: 0;
        li {
            &:not(:last-of-type):after {
                content: " ";
                height: 1px;
                border-bottom: 1px solid $disabled-background;
                width: 80%;
                text-align: center;
                position: absolute;
                left: 10%;
            }
            a {
                font-size: 13px;
                padding: 10px;
                display: block;
                text-transform: none;
                color: $text-color;
                white-space: nowrap;
                text-decoration: none;
                cursor: pointer;
                &:hover {
                    color: $grey-text;
                }
                &.active {
                    font-weight: bold;
                }
            }
        }
    }
}

.list-unstyled {
    padding-left: 0;
    list-style: none;
    margin-bottom: 0;
}

.section-with-details {
    text-align: right;
    .section-details {
        font-size: 16px;
        line-height: 18px;
        color: $text-default;
        display: inline-block;
        max-width: 64%;
    }
    .red-link {
        margin-left: 40px;
        &.empty {
            margin-left: 107px;
        }
    }
}

.error-text {
    color: $error-color;
    font-size: 13px;
    font-style: italic;
}

.mw-120 {
    min-width: 120px !important;
}

.ox-s {
    overflow-x: hidden;
}

.w155 {
    width: 155px !important;
}

.pointer {
    cursor: pointer !important;
}

.cemsSummary {
    &.mt-5 {
        margin-top: 0 !important;
    }
    .card {
        border: none;
        box-shadow: none;
        background-color: white;
        margin: 0;
        .form-panel {
            background-color: white;
        }
    }
}

.dotsWhenNoPlace {
    .mat-form-field-label {
        white-space: nowrap !important;
        overflow: hidden !important;
        text-overflow: ellipsis !important;
    }
}

.cdk-visually-hidden {
    display: none;
}

.pointer {
    cursor: pointer;
}

@include respond(phone) {
    .recap-my-profil ol,
    .recap-promo ol,
    .recap-my-profil ul.list-arrows,
    .recap-promo ul.list-arrows,
    .row.with-speech .speech .files ul {
        padding-left: 0 !important;
    }
}
