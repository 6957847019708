@import "./variables";
.card {
    position: relative;
    background-color: #fff;
    border-radius: 5px;
    box-shadow: 0px 0px 18px 0px rgb(0 0 0 / 7%);
    padding: 0 14px;
    margin: 30px 0;
    text-align: center;
    &.disabled {
        .section-title {
            color: $disabled-card-header-text-color;
        }
    }
    .section-title {
        margin: 0;
        text-align: left;
        font-weight: 600;
    }
    .red-link {
        float: right;
        margin-top: 14px;
        font-size: 16px;
        font-weight: 600;
    }
    .card-body {
        padding: 1rem;
        font-size: 1rem;
        font-weight: normal;
        line-height: 1.333rem;
        &.pl-0 {
            padding: 1rem 0;
        }
        h5.header {
            font-weight: 600;
            margin-bottom: 0.5rem;
            margin-top: 0.5rem;
        }
    }
    h5 {
        font-weight: normal;
        padding-bottom: 1.5px;
        margin-bottom: 1rem;
    }
}
