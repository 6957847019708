@import "./variables";
@import "./breakpoints";
.warning {
    position: absolute;
    right: -10px;
    top: 10rem;
    border-radius: 5px 0 0 5px;
    padding: 1.2rem;
    box-shadow: 0 0 2rem 0 rgb(0 0 0 / 22%);
    font-size: 14px;
    line-height: 20px;
    color: $red-text-color;
    width: 250px;
    // text-align: left;
    background: #fff url("../../assets/img/alert.svg") 0 0 no-repeat;
    z-index: 2;
    &::before {
        content: "";
        position: absolute;
        top: -10px;
        right: 0;
        width: 0;
        height: 0;
        border-style: solid;
        border-width: 10px 0 0 10px;
        border-color: transparent transparent transparent #969696;
    }
    &.red {
        &.distr {
            top: unset !important;
        }
        color: white;
        background: $red-text-color;
        z-index: 100;
        top: 50px;
        padding: 1.2rem 1rem;
        font-size: 0.778rem;
        z-index: 100;
        line-height: 1.111;
        p {
            font-weight: 600;
        }
    }
    &.blue-offer-gpa {
        color: $text-color;
        background: #fff url("../../assets/img/alert-blue.svg") 0 0 no-repeat;
    }
    &.top-auto {
        top: auto;
    }
    a {
        color: $white;
        text-decoration: underline;
        display: block;
    }
    &.in-line {
        position: initial;
        width: 100%;
    }
}

.alert-block {
    background: url("../../assets/img/alert-element.svg") 0 0 no-repeat;
    background-size: contain;
    margin-top: 3rem;
    padding: 4rem;
    width: 200px;
    min-height: 16rem;
    left: 0;
    right: auto;
    box-shadow: none;
    color: $error-color;
}

.error-block {
    box-shadow: 0 0 2rem 0 rgb(0 0 0 / 22%) !important;
    border-radius: 0 5px 5px 0;
    margin-top: 1rem;
    min-height: 116px;
    left: 50px;
    right: auto;
    margin-left: 16px;
    padding-left: 6px;
    padding-right: 6px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    background-color: white;
    &::before {
        content: "";
        position: absolute;
        left: -30px;
        width: 31px;
        min-height: 116px;
        background: url("../../assets/img/error.png") 0 0 no-repeat;
    }
    .text {
        display: inline-block;
        font-size: 12px;
        color: #f51837;
    }
}

@include respond(tab-port) {
    .my-profile {
        .warning-box {
            position: initial;
            margin-top: 1rem;
            width: 100%;
            &:before {
                display: none;
            }
        }
        .alert-block {
            position: relative;
            width: 14rem;
            text-align: left;
            top: 0;
            min-height: 3rem;
            padding: 1rem 1.5rem;
            margin: 0;
            font-size: 0.778rem;
        }
    }
}

@each $index in $numbers {
    .warning-#{$index} {
        top: $index * 170 + px;
        &.distr {
            margin-top: $index * 125 + px;
        }
    }
    .offerSeparator-#{$index} {
        margin-top: $index * 170 + px;
    }
    .offerCapitalSeparator-#{$index} {
        margin-top: $index * 125 + px;
    }
}

@media (max-width: 767px) {
    @each $index in $numbers {
        .warning-#{$index} {
            top: 0px;
        }
        .offerSeparator-#{$index} {
            margin-top: 0px;
        }
        .offerCapitalSeparator-#{$index} {
            margin-top: 0px;
        }
    }
}
