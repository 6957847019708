@mixin respond($breakpoint) {
  @if $breakpoint == phone {
    @media (max-width: 767px) {
      @content
    };
  }

  @if $breakpoint == tab-port {
    @media (max-width: 991px) {
      @content
    };
  }

  @if $breakpoint == tab-port-big {
    @media (max-width: 1024px) {
      @content
    };
  }
  @if $breakpoint == tab-land {
    @media (max-width: 75em) {
      @content
    };
  }

  @if $breakpoint == min-big {
    @media (min-width: 75em) {
      @content
    };
  }
  @if $breakpoint == big-desktop {
    @media (min-width: 112.5em) {
      @content
    };
  }
}
