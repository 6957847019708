@import "./variables";
@import "./breakpoints";
.speech {
    padding: 15px 5px;
    text-align: left;
    .speech-title {
        font-size: 20px;
        color: $text-color;
    }
    p {
        font-size: 15px;
        line-height: 18px;
        color: $text-default;
        padding-top: 10px;
        padding-bottom: 10px;
        margin-bottom: 0;
    }
}

.questions-with-acceptance {
    .speech {
        padding: 0;
    }
}

.speech-title {
    font-size: 20px;
    color: $text-color;
}
