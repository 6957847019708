@import "./variables";
.icon-help {
    background: #189bd5 url("../img/icon-help.svg") 50% no-repeat;
    background-size: auto 13px;
    padding: 0px 10px;
    position: relative;
    top: -1px;
    margin-left: 10px;
    border-radius: 14px;
    font-size: 15px;
    margin-right: 10px;
}

.riscue {
    .icon-help {
        top: 2px;
    }
}

.table-fake {
    .icon-help {
        top: 1px;
    }
}

.table-header {
    .icon-help {
        top: 1px;
    }
}

.icon-aside {
    display: inline-block;
    line-height: 1;
    position: relative;
    vertical-align: middle;
    background: url("../img/icone-trombone.svg") no-repeat;
    position: relative;
    top: -24px;
    left: 8px;
    height: 68px;
    width: 44px;
    z-index: 100;
}

.icon-filter {
    background: #189bd5 url("../img/icone-filtre.svg") 50% no-repeat;
    width: 35px;
    height: 35px;
    background-size: auto 13px;
    padding: 5px 15px;
    position: relative;
    margin-left: 4px;
    cursor: pointer !important;
}
