@import "./variables";
.p-tooltip {
    &.tooltip640 {
        .p-tooltip-text {
            width: 640px;
        }
    }
    &.tooltip500 {
        .p-tooltip-text {
            width: 500px;
        }
    }
    position: absolute !important;
    &.tooltip-lg {
        .p-tooltip-text {
            width: 700px;
        }
    }
    &.no-bg {
        .p-tooltip-text {
            background-color: transparent !important;
            border: none;
            text-align: center;
        }
    }
    &.peri-charts {
        .p-tooltip-text {
            width: 600px;
            height: 420px;
            img {
                max-width: 100%;
            }
        }
    }
    .p-tooltip-text {
        background: $background-tooltip-color !important;
        color: $grey-text !important;
        width: 320px;
        border-radius: 3px;
        border: 1px solid $input-border;
        padding: 5px 10px;
        font-size: 14px;
        .tooltip-header {
            color: $text-color;
            font-weight: bold;
        }
    }
}

@media (max-width: 767px) {
    .p-tooltip {
        &.peri-charts {
            .p-tooltip-text {
                width: 400px;
                height: 320px;
            }
        }
    }
}
