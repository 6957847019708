@import './breakpoints';
@import './variables';

.mat-checkbox {
  &:not(.mat-checkbox-disabled),
  &:not(.mat-checkbox-transparent) {
    &.mat-checkbox-indeterminate.mat-accent .mat-checkbox-background,
    &.mat-checkbox-checked.mat-accent .mat-checkbox-background {
      background-color: $blue-btn-color;
    }
  }
  &.mat-checkbox-disabled {
    // @include color-form-control-label-disabled;
    .mat-checkbox-frame {
      // @include color-form-control-label-disabled;
    }
    .mat-checkbox-indeterminate.mat-accent .mat-checkbox-background,
    .mat-checkbox-checked.mat-accent .mat-checkbox-background {
      background-color: none !important;
    }
  }
  label {
    width: auto;
  }
  .mat-checkbox-inner-container {
    height: 22px !important;
    width: 22px !important;
    .mat-checkbox-input {
      display: none;
    }
    .mat-checkbox-ripple {
      display: none !important;
    }
    .mat-checkbox-background {
      border-radius: 4px;
    }
    .mat-checkbox-frame {
      background-color: white;
      border-radius: 2px;
      border: 2px solid $blue-btn-color;
    }
  }
  .mat-checkbox-label {
    font-size: 13px;
    padding-top: 3px;
    line-height: normal;
    color: $grey-text;
  }
}

.selection-list {
  .success-mark,
  .checkbox-error {
    position: absolute;
    top: 60px;
  }
  .checkbox-error {
    right: 20%;
    color: $error-color;
    font-size: 13px;
    font-style: italic;
  }
  .input-inside-checkbox-list {
    position: relative;
    top: -60px;
    right: -30%;
  }
}

.mat-selection-list.mat-list-base {
  padding: 0;
  padding-top: 10px;
  .mat-list-item {
    height: 37px;
    width: auto;
    display: table;
    .mat-list-item-content {
      &.mat-list-item-content-reverse {
        padding: 0;
        flex-direction: initial;
        justify-content: flex-start;
      }
      .mat-pseudo-checkbox {
        width: 20px;
        height: 20px;
        border: 1px solid $input-border;
        &.mat-pseudo-checkbox-checked {
          border: 1px solid $input-border;
          &:after {
            top: 5.2px;
            left: 3.2px;
            width: 9px;
            color: $grey-text;
          }
        }
      }
      .mat-list-text {
        font-size: 13px;
        line-height: 15px;
        color: $grey-text;
        padding-right: 0 !important;
        padding-left: 8px !important;
      }
    }
  }
}

.checkbox-custom {
  .mat-checkbox-label {
    font-size: 20px;
    line-height: 22px;
    margin-top: 0;
    color: $text-color;
    font-weight: 600;
  }
}

.big-text {
  .mat-checkbox-layout {
    white-space: inherit;
    .mat-checkbox-inner-container {
      margin-top: 3px;
    }
  }
}

.break-spaces {
  .mat-checkbox-label {
    white-space: break-spaces;
  }
}

.mat-checkbox {
  &:not(.mat-checkbox-disabled),
  &:not(.mat-checkbox-transparent) {
    &.mat-checkbox-indeterminate.mat-accent .mat-checkbox-background,
    &.mat-checkbox-checked.mat-accent .mat-checkbox-background {
      background-color: transparent;
      transform: scale(0.8);
    }
  }
}

@include respond(phone) {
  .big-text {
    .mat-checkbox-layout {
      width: 90%;
    }
  }
}
