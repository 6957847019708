@import "./variables";

.mat-datepicker-close-button {
    display: none !important;
}

.mat-datepicker-content {
    display: block;
    border-radius: 4px;
    box-shadow:
        0px 2px 4px -1px rgb(0 0 0 / 20%),
        0px 4px 5px 0px rgb(0 0 0 / 14%),
        0px 1px 10px 0px rgb(0 0 0 / 12%);
    background-color: #fff;
    color: rgba(0, 0, 0, 0.87);
    .mat-calendar-header {
        padding: 0 8px;
        .mat-calendar-controls {
            display: flex;
            margin: 5% calc(33% / 7 - 16px);
            button:not(:disabled),
            [type="button"]:not(:disabled),
            [type="reset"]:not(:disabled),
            [type="submit"]:not(:disabled) {
                background: none;
                &:hover {
                    background: $color-option-bg-hover;
                }
            }
        }
    }
    .mat-calendar-content {
        padding: 0 8px 8px 8px;
        outline: none;

        .mat-calendar-table {
            border-spacing: 0;
            border-collapse: collapse;
            width: 100%;
            .mat-calendar-body {
                .mat-calendar-body-cell {
                    &.mat-calendar-body-disabled {
                        .mat-calendar-body-cell-content {
                            background: $color-option-bg-hover;
                            color: $disabled-text-color;
                            border: none;
                        }
                    }
                    .mat-calendar-body-cell-content {
                        &:hover {
                            background: $color-option-bg-hover;
                        }
                    }
                    .mat-calendar-body-selected {
                        background: $blue-btn-color !important;
                        &:hover {
                            background: $blue-btn-hover-color;
                        }
                    }
                }
            }
        }
    }
}

.mat-datepicker-toggle {
    .mat-icon-button {
        width: unset !important;
        height: unset !important;
        display: inline-flex !important;
        justify-content: center !important;
        align-items: center !important;
        font-size: inherit !important;
    }
}

.mat-datepicker-toggle-default-icon {
    position: absolute;
    right: 16px;
    bottom: -20px;
    color: $text-default;
}

mat-form-field:not(.mat-form-field-disabled) {
    .mat-datepicker-toggle-active {
        color: $blue-btn-color;
    }
    .mat-datepicker-toggle-default-icon {
        &:hover {
            color: $blue-btn-hover-color;
        }
    }
}

.mat-focus-indicator {
    background-color: transparent !important;
}
