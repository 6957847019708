@import "./variables";

$color-cb-bg: #192342;

.mat-combobox {
  .mat-radio-group {
    &.ng-invalid.ng-touched {
      .mat-radio-button {
        &.selected-item {
          .mat-radio-label {
            background: $error-color;
          }
        }
        .mat-radio-label {
          background: $error-color;
        }
      }
    }
    .mat-radio-button {
      padding-top: 10px;
      &.mat-radio-disabled {
        .mat-radio-label {
          background: $disabled-background;
          color: $middle-grey;
          opacity: 0.65;
        }
      }
      &.selected-item {
        .mat-radio-label {
          background: $green-color;
          border: none;
        }
        &:focus {
          .mat-radio-label {
            background: $green-color;
            border: none;
          }
        }
      }
      &:not(:first-child) {
        margin-left: 10px;
      }
      .mat-radio-label {
        min-height: 50px;
        min-width: 98px;
        color: $white;
        border-radius: 8px;
        transition: $transition-config;
        cursor: pointer;
        vertical-align: middle;
        padding: 0.7rem 1.5rem;
        position: relative;
        background: $text-color;
        border-color: $text-color;
        text-align: center;
        display: inline-block;
        input[type="radio"] {
          border: 0;
          clip: rect(0 0 0 0);
          height: 1px;
          margin: -1px;
          overflow: hidden;
          padding: 0;
          position: absolute;
          width: 1px;
        }
        &:focus {
          box-shadow: none;
        }
        .mat-radio-container {
          width: 0;
          .mat-radio-outer-circle {
            display: none;
          }
        }
        .mat-radio-label-content {
          padding-left: 0;
        }
      }
    }
  }
}

.mat-combobox {
  &.disabled {
    .mat-radio-button {
      &.selected-item {
        .mat-radio-label {
          background: $green-color;
          color: white;
          cursor: default;
        }
      }
      .mat-radio-label {
        background: $disabled-background;
        color: $grey-text;
        cursor: default;
      }
    }
  }

  &:not(.disabled) {
    &:hover {
      mat-label {
        //@include label-hover;
      }
    }
  }

  mat-label {
    // @include label-reqular;
    // @include font-normal;
  }
}

.vertical-combobox {
  max-height: 500px;
  overflow: auto;
  .select,
  .date-time {
    // @include font-normal;
    // @include value-reqular;
    font-weight: 800;
  }
  .date-time {
    margin-left: 18px;
  }
  .select {
    margin-left: 38px;
  }
  .mat-combobox {
    .mat-radio-button {
      display: block;
      margin: 2px 0 2px 40px;
      .mat-radio-label-content {
        margin-left: 30px;
      }
    }
  }
}
