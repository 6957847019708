@import "./variables";

@media (max-width: 767px) {
    h1 {
        font-size: 22px;
    }
    .col-xs-6 {
        width: 50% !important;
    }
    .back-btn,
    .success-mark,
    .error-mark {
        display: none !important;
    }

    .warning,
    .warning.red,
    .warning.blue-offer-gpa {
        &::before {
            display: none;
        }
        position: relative;
        top: 0;
        right: 0;
        width: 90%;
        left: 5%;
        border-radius: 5px;
        margin-top: 20px;
        margin-bottom: 20px;
    }

    .valider-button-block {
        margin-top: 20px;
    }

    button.validation-button {
        width: 80%;
    }

    .card {
        margin: 1rem 0rem !important;
        .red-link {
            margin-top: 0;
        }
    }

    .xs-100 {
        width: 100%;
    }

    .collapse-link-block {
        margin-left: 0 !important;
    }

    .question-error-wrapper {
        position: relative;
    }

    .question-error-block {
        margin-top: 0.3rem;
        margin-bottom: 1rem;
    }

    .plan-buttons {
        .btn {
            margin: 0.5rem !important;
        }
    }

    .my-profile-steps {
        .warning:not(.red) {
            margin-top: -90px;
            margin-bottom: 125px;
        }

        .questions-with-descr {
            .question-buttons {
                button {
                    width: 80% !important;
                    margin: 0 auto !important;
                    margin-bottom: 22px !important;
                }
            }
        }
    }

    .tooltip-lg {
        left: auto !important;
        .p-tooltip-text {
            width: 100% !important;
        }
    }

    .prev-question,
    .next-question {
        display: none;
        &.mobile {
            display: inline-block !important;
            position: absolute;
            left: 0;
        }
    }

    .prev-question.mobile {
        transform: rotate(270deg);
    }

    .next-question.mobile {
        right: -90%;
        transform: rotate(270deg);
    }

    .check-mark,
    .error-mark {
        display: none !important;
    }

    .container {
        padding-bottom: 14rem !important;
    }

    .hidden-xs,
    .steps {
        display: none !important;
    }

    .error-block {
        position: relative;
        left: 0;
        margin-left: 0;
        border-radius: 5px;
        &::before {
            display: none;
        }
    }

    .mat-form-field {
        .mat-error {
            position: inherit;
            overflow: hidden !important;
            text-overflow: ellipsis !important;
        }
    }

    h1.section-title {
        font-size: 22px;
        line-height: 24px;
    }

    .after-input {
        margin-top: 20px;
    }

    .box-separator {
        position: relative;
        left: 0;
        font-weight: 600;
    }

    .error-new-row, .error-new-row-991 {
        .form-group {
            margin-bottom: 30px;
        }
    }

    .btn {
        &.get-sms {
            margin-top: 0;
        }
    }

    .visible-xs {
        display: block !important;
    }

    table.visible-xs {
        display: table !important;
    }

    tr.visible-xs {
        display: table-row !important;
    }

    th.visible-xs,
    td.visible-xs {
        display: table-cell !important;
    }

    .border-bottom-dashed {
        padding-bottom: 20px;
    }

    .question-title {
        padding-bottom: 20px;
        text-align: center !important;
    }

    .mt-0-xs {
        margin-top: 0 !important;
    }

    .table-like {
        position: relative;
        top: auto;
        left: auto;
        padding: 0 20px;
        width: 100%;
        transform: none;
        .btn {
            width: 100%;
        }
    }
}

@media (max-width: 991px) {
    .error-new-row-991 {
        .mat-checkbox-layout {
            white-space: inherit;
        }
        .mat-form-field {
            .mat-error {
                position: inherit;
                white-space: break-spaces;
                line-height: 1;
                overflow: hidden !important;
                text-overflow: ellipsis !important;
            }
        }
    }
}

@media (min-width: 768px) and (max-width: 991px) {
    .warning,
    .warning.smart,
    .warning.red {
        &::before {
            display: none;
        }
        position: relative;
        top: 0;
        right: 0;
        width: 90%;
        left: 5%;
        border-radius: 5px;
        margin-top: 20px;
        margin-bottom: 20px;
    }

    .error-new-row-991 {
        .form-group {
            margin-bottom: 20px;
        }
    }

    .my-profile-steps {
        .warning:not(.red) {
            margin-top: -90px;
            margin-bottom: 125px;
        }
    }

    .question-error-wrapper {
        position: relative;
        right: 0;
    }

    .question-error-block {
        margin-top: 0.3rem;
        margin-bottom: 1rem;
    }

    .error-block {
        position: relative;
        left: 0;
        margin-left: 0;
        border-radius: 5px;
        &::before {
            display: none;
        }
    }

    .steps {
        top: 60px !important;
    }

    .hidden-sm {
        display: none !important;
    }

    .mat-form-field {
        .mat-error {
            position: inherit;
        }
    }

    .after-input {
        margin-top: 20px;
    }

    .box-separator {
        position: relative;
        left: 0;
        font-weight: 600;
    }

    .visible-sm {
        display: block !important;
    }

    table.visible-sm {
        display: table !important;
    }

    tr.visible-sm {
        display: table-row !important;
    }

    th.visible-sm,
    td.visible-sm {
        display: table-cell !important;
    }
}

@media (min-width: 960px) and (max-width: 1365px) {
    .hidden-md {
        display: none !important;
    }

    .warning {
        width: 200px;
    }
}

@media (min-width: 1366px) {
    .hidden-lg {
        display: none !important;
    }
}

@media (max-width: 1020px) {
    .table-responsive {
        width: 100%;
        margin-bottom: 15px;
        overflow-y: hidden;
        overflow-x: scroll;
        border: 1px solid #dddddd;
    }
}

@media screen and (max-width: 767px) {
    .modify-btn {
        position: relative !important;
        transform: none !important;
        margin-bottom: 40px !important;
        top: 0 !important;
        width: 100% !important;
        margin-top: 20px !important;
        right: auto !important;
    }
}
