@import "./variables";

.file-uploader {
    file-upload {
        background: none;
        outline: none;
        padding: 0;
        min-height: 70px;
        &.ng-touched.ng-invalid {
            .upload-text {
                // border: 1px solid $error-color;
            }
        }
        .upload-input {
            cursor: pointer;
            display: inline-block;
            position: initial;
            transform: none;
            margin-top: 10px;
            outline: none;
            text-align: center;
            font-size: 13px;
            file-upload-drop-zone {
                .icon {
                    display: none;
                }
                .upload-text {
                    min-height: 48px;
                    border-radius: 8px;
                    color: $white !important;
                    transition: $transition-config;
                    cursor: pointer;
                    line-height: 1rem;
                    margin: 0;
                    vertical-align: middle;
                    padding: 0.5rem 1.5rem;
                    background: $blue-btn-color;
                    border-color: $blue-btn-color;
                }
            }
        }
    }
    .file-list {
        color: $text-default;
        font-size: 13px;
        .file-item {
            cursor: pointer;
            overflow-wrap: anywhere;
            .delete-icon {
                color: $error-color;
                position: absolute;
                left: 0px;
            }
        }
    }
    .error-list {
        font-size: 13px;
        color: $error-color;
    }
}

.in-table-cell {
    .file-uploader {
        .upload-input {
            width: auto;
            margin-left: 0;
            .upload-text {
                padding: 9px 4px;
            }
        }
    }
}

@media (min-width: 768px) and (max-width: 959px) {
    .file-uploader {
        file-upload {
            .upload-input {
                margin-left: 0px;
                width: 100%;
            }
        }
    }
}
