@supports (-webkit-backdrop-filter: blur(1px)) {
    .mat-datepicker-toggle-default-icon {
        // top: -54px;
    }
    .mat-form-field-prefix {
        // top: -20px;
    }
    .fraisInclus {
        // top: -20px;
        position: relative;
    }
    .mat-form-field {
        margin-top: 48px;
        height: 52px;
    }
    .separated-title {
        padding-bottom: 10px;
    }
    .mat-form-field .mat-form-field-wrapper .mat-form-field-flex .mat-form-field-infix .mat-input-element,
    .mat-form-field .mat-form-field-wrapper .mat-form-field-flex .mat-form-field-infix .mat-select {
        padding: 15px 10px;
    }
}
