$numbers: 1, 2, 3, 4, 5, 6, 7, 8, 9, 10;

$background-color: #f2f2f2;
$background-tooltip-color: #f2f2f2;
$white: #ffffff;
$error-color: #f51837;
$red-text-color: #e65214;
$text-color: #189bd5;

$blue-btn-color: #189bd5;
$blue-btn-hover-color: #117099;

$red-btn-color: #dc3545;
$red-btn-hover-color: #c82333;

$green-color: #bece08;
$green-color2: #94a009;
$header-link: #696969;
$grey-text: #4d4d4d;
$text-default: #4c4c4c;
$middle-grey: #808080;
$tabs-text-color: #a6a6a6;
$input-border: #d9d9d9;
$viewed-link-color: #a8d1eb;
$form-control-text-color: #555;
$color-option-bg-hover: #f5f6f7;
$color-default-link: #404040;

$disabled-background: #e5e5e5;
$disabled-input-background: #eee;
$disabled-text-color: #bbb;
$disabled-card-header-text-color: #bbb;

$alert-danger-color: #a94442;
$alert-danger-bg-color: #f2dede;
$alert-danger-border-color: #ebccd1;

$signature-orange-bg-color: #e8773e;

$transition-config: 0.5s ease all;
