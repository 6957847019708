@import "./variables";
.txt-default {
    font-size: 0.8rem;
    line-height: 1rem;
    color: $text-default;
    margin-bottom: 0;
    &.disabled {
        color: $disabled-text-color;
    }
}

.text-default {
    color: $text-default !important;
}

.text-blue {
    color: $text-color !important;
}

.text-red {
    color: red !important;
}

.text-black {
    color: black !important;
}

.text-right {
    text-align: right;
}

.color-def-link {
    color: $color-default-link;
}

h1,
.h1 {
    font-size: 34px;
}

.fw-600 {
    font-weight: 600 !important;
}

.fw-700 {
    font-weight: 700 !important;
}

.text-underline {
    text-decoration: underline;
}

.text-italic {
    font-style: italic;
}

.like-speech-title {
    font-size: 20px;
    color: $text-color;
}

.txt-mentions {
    font-size: 13px;
    line-height: 18px;
    color: $grey-text;
}

.main-intro {
    text-align: left;
    font-size: 19px;
    font-weight: 600;
}

.label-like {
    color: $text-default;
    text-align: center;
    font-size: 14px;
    line-height: 18px;
    font-weight: 600;
}

.link-like {
    text-decoration: underline;
    &:hover {
        cursor: pointer;
        text-decoration: none !important;
    }
    &.gray {
        color: $text-default;
        font-size: 14px;
        line-height: 18px;
        font-weight: 600;
    }
    &.black {
        color: $color-default-link;
        font-size: 14px;
        line-height: 18px;
        font-weight: 600;
    }
}

.fs-13 {
    font-size: 13px;
}

.fs-14 {
    font-size: 14px;
}

.fs-15 {
    font-size: 15px;
}

.fs-16 {
    font-size: 16px;
}

.fs-18 {
    font-size: 18px;
}

.fs-24 {
    font-size: 24px;
}

.fs-30 {
    font-size: 30px;
}

em.small {
    font-style: normal;
    font-weight: 600;
}

.disabled-text {
    color: $disabled-card-header-text-color !important;
}

.pl-0 {
    padding-left: 0 !important;
}

.recap-li-font-style {
    font-size: 14px;
    color: $grey-text;
    line-height: 1.5;
}
