@import "./variables";

@mixin label-reqular {
}

@mixin label-hover {
    color: $text-color;
    opacity: 1 !important;
}

@mixin option-item {
    margin: 0;
    padding: 0 20px;
    line-height: 24px !important;
    height: 24px !important;
    &:hover {
        background-color: $color-option-bg-hover;
    }
    &.auto-height {
        height: auto !important;
        white-space: inherit;
    }
}
